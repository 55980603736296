@import url(https://fonts.googleapis.com/css2?family=DM+Sans&display=swap);
.MuiInputBase-root {
    color: rgb(187, 38, 163)!important;
}

.MuiInputLabel-outlined {
    color: rgb(187, 38, 163)!important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: rgb(187, 38, 163)!important;
    border-width: 2px;
}

body {
    margin: 8px 0px!important;
}

.makeStyles-paper-1 {
    border-radius: 5px;
    width: 30%!important;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    text-align: center;
}

@media (max-width: 800px) {
    .makeStyles-paper-1 {
        width: 60%!important;
    }
    .trails-text {
        font-size: 35px!important;
        height: 35px!important;
        line-height: 30px!important;
    }
    .trails-text2 {
        font-size: 25px!important;
        height: 30px!important;
        line-height: 25px!important;
    }
    .card {
        flex: 0 0 75%!important;
        max-width: 75%!important;
        margin-bottom: 7%!important;
        margin-top: 8%!important;
        padding: 5%!important;
        margin-left: auto!important;
    }
}

.trails-main {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.trails-text {
    position: relative;
    width: 100%;
    line-height: 40px;
    color: #000b49;
    font-size: 40px;
    font-weight: 800;
    will-change: transform, opacity;
    overflow: hidden;
}

.trails-text>div {
    overflow: hidden;
}

.trails-text2 {
    position: relative;
    width: 100%;
    line-height: 35px;
    color: #000b49;
    font-size: 35px;
    font-weight: 800;
    will-change: transform, opacity;
    overflow: hidden;
    text-align: left;
}

.trails-text2>div {
    overflow: hidden;
}

@media(max-width:890px) {
    .hexagono {
        margin-right: 0px !important;
    }
    .container-card {
        margin-bottom: 6.5% !important;
    }
}

@media(max-width:800px) {
    .container-card,
    .container-card-2 {
        width: 70% !important;
        justify-content: center !important;
        margin-top: 6% !important;
    }
    .hexagono2 {
        margin-right: 0px !important;
    }
    .hexagono-card {
        margin-top: 30%;
        margin-bottom: 30%;
    }
}


/* .card {
    flex: 0 0 17%;
    max-width: 17.5%;
    padding: 2% 3%;
    text-align: left; 
    border: 1px solid #d7e1e6; 
    grid-column: span 12;
    -webkit-box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3);
    height: 170px;
    margin: auto;
    margin-left: auto; 
    line-height:  auto;  
  } */
